import { authRequest } from "../../utils/request/authRequest";

interface UserInterface {
  profile?: {
    avatarUrl?: string;
    birthdate?: string;
    firstName?: string;
    lastName?: string;
    about?: string;
    bio?: string;
    description?: string;
    languages?: any;
    education?: any;
    socialAccountLinks?: string[];
    socialAccountsData?: any;
    roles?: string[];
    industries?: string[];
    interests?: string[];
  };
  email?: string;
  username?: string;
}

interface UserInterfaceInfo extends UserInterface {
  roles: string[];
  walletAddresses: string[];
  referralCode: string;
}

export const getMe = async (): Promise<UserInterfaceInfo> => {
  const response = await authRequest(`v1/users/me`, {
    method: "GET",
  });
  return response.data;
};

export const patchMe = async (user: UserInterface): Promise<number | any> => {
  const response = await authRequest(
    `v1/users/me`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": `application/json`,
      },
    },
    { ...user },
  );
  return response.data;
};

export const patchMev1 = async (user: UserInterface): Promise<any> => {
  const response = await authRequest(
    `v1/users/me`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": `application/json`,
      },
    },
    { ...user },
  );
  return response;
};
